import * as React from 'react'
import PropTypes from 'prop-types'

// Emoji Span Component
const Emoji = ({ label, emoji, space }) => (
	<span role="img" aria-label={label} className={`px-10 lg:px-${space}`}>
		{emoji}
	</span>
)

Emoji.propTypes = {
	label: PropTypes.string.isRequired,
	emoji: PropTypes.string.isRequired,
}

export default Emoji
