import * as React from 'react'
import { graphql, Link } from 'gatsby'
import PropTypes from 'prop-types'

import Layout from '../components/layout'

import Image from '../components/partials/image'
import HueCard from '../components/partials/hue'
import Emoji from '../components/partials/emoji'

const CareersPage = ({ data }) => {
	const careers = data.strapi.careersPage

	return (
		<Layout page={careers} metadata={careers.metadata}>
			<div className="container pt-80 sm:pt-120 lg:pt-0">
				{/* Headline Section */}
				<section className="py-20 lg:py-40">
					<h1>{careers.headline}</h1>
				</section>

				{/* Hero Unit */}
				<section className="py-20 lg:py-40 xl:py-80">
					<Image asset={careers.slideshow[0]} />
				</section>

				<section className="py-60 md:py-80">
					<h2 className="h1 text-center pb-10 md:pb-20 lg:pb-40">
						{careers.listing_headline}
					</h2>

					<div className="grid grid-cols-1 auto-rows-fr gap-30 lg:gap-40 py-40">
						{careers.order.map(({ id, opening }) => {
							return (
								<Link to={`/careers/${opening.slug}`} key={`job_opening_${id}`}>
									<Opening title={opening.title} />
								</Link>
							)
						})}
					</div>
				</section>

				{/* Emoji Highlights */}
				<section className="py-40 xl:py-80">
					<h2 className="leading-loose">
						Work from Goa
						<Emoji label="Work from Goa" emoji="&#x1F334;" space="20" />
						High Autonomy
						<Emoji label="Take pride" emoji="&#x1F920;" space="20" />
						Meaningful work
						<Emoji label="Make healthy profits" emoji="&#x1F9E0;" space="20" />
						Fair Pay
						<Emoji label="Love dogs" emoji="&#x1F596;" space="20" />
						Five days a week
						<Emoji
							label="Take ownership"
							emoji="&#x1F5D3;&#xFE0F;"
							space="20"
						/>
						Friends and Co-workers
						<Emoji
							label="Get our hands dirty"
							emoji="&#x1F9D1;&#x200D;&#x1F91D;&#x200D;&#x1F9D1;"
							space="20"
						/>
						Hard work
						<Emoji label="Long-Term" emoji="&#x1F4AA;" space="20" />
						Design NOT Art
						<Emoji
							label="Obsess over details"
							emoji="&#x1F468;&#x200D;&#x1F680;"
							space="20"
						/>
						Learn everyday
						<Emoji label="Ice-Cream" emoji="&#x1F6A3;" space="15" />
						No Egos and zero politics
						<Emoji
							label="Trust our gut"
							emoji="&#x1F937;&#x200D;&#x2640;&#xFE0F;"
							space="15"
						/>
					</h2>
				</section>
			</div>
		</Layout>
	)
}

CareersPage.propTypes = {
	data: PropTypes.shape({
		strapi: PropTypes.shape({
			careersPage: PropTypes.shape({}).isRequired,
		}).isRequired,
	}).isRequired,
}

// Opening Card with Hue Hover Effect
const Opening = ({ title }) => {
	return (
		<HueCard>
			<div className="flex w-full justify-between items-center">
				<h3 className="h2 leading-tight group-hover:text-blue group-hover:underline">
					{title}
				</h3>
				<div className="invisible sm:visible w-30 lg:w-40 h-30 lg:h-40">
					<svg
						width="40"
						height="40"
						viewBox="0 0 40 40"
						className="relative h-full w-full"
					>
						<path
							fill="transparent"
							strokeWidth="4"
							stroke="#333333"
							d="M 2 20 L 38 20"
						/>
						<path
							fill="transparent"
							strokeWidth="4"
							stroke="#333333"
							d="M 20 2 L 38 21"
						/>
						<path
							fill="transparent"
							strokeWidth="4"
							stroke="#333333"
							d="M 38 19 L 20 38"
						/>
					</svg>
				</div>
			</div>
		</HueCard>
	)
}

Opening.propTypes = {
	title: PropTypes.string.isRequired,
}

export const query = graphql`
	{
		strapi {
			careersPage {
				headline
				slideshow {
					id
					url
					ext
					height
					width
					name
					imageFile {
						publicURL
						childImageSharp {
							gatsbyImageData(
								width: 1290
								transformOptions: { cropFocus: CENTER, fit: COVER }
								layout: CONSTRAINED
								formats: [AUTO, WEBP, AVIF]
							)
						}
					}
				}
				listing_headline
				order {
					id
					opening {
						slug
						title
					}
				}
				footer_headline
				footer_link_url
				footer_link_text
				metadata {
					meta_title
					meta_desc
					meta_image {
						url
						imageFile {
							publicURL
						}
					}
				}
			}
		}
	}
`

export default CareersPage
